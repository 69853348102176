<template>
    <y-loading
        :active="$wait.is('loading-page')"
        height="calc( 100vh - 200px )"
    >
        <y-page-head
            :title="title"
            :desc="subtitle"
            icon="md-account"
        >
            <template slot="actions">
                <y-button
                    id="refresh-button"
                    type="button"
                    icon="md-refresh"
                    aria-label="Refresh"
                    @click.prevent="reFetch"
                />
            </template>
        </y-page-head>

        <main v-if="app">
            <div class="row">
                <div class="col-sm-8">
                    <y-panel
                        :title="$t('job.user')"
                        icon="md-account-box"
                        class="mb0"
                    >
                        <div class="form-title">
                            {{ app.applicant.name_full }}
                        </div>

                        <template slot="functions">
                            <a
                                v-if="app.CV && app.CV.length"
                                :href="app.CV[0].link"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="button xs"
                            >
                                {{ $t('job.view') }}
                            </a>
                        </template>

                        <div class="row">
                            <div class="col">
                                <y-form-email-label
                                    :content="app.applicant.email"
                                    copy
                                    :prefix="$t('job.email')"
                                />
                            </div>
                            <div class="col">
                                <p class="form-email-label">
                                    {{ $t('job.mobile') }}
                                    {{ app.applicant.mobile | digits }}
                                </p>
                            </div>
                        </div>
                    </y-panel>

                    <y-timeline :timeline="app.timeline" />
                </div>

                <div class="col-sm-4">
                    <y-panel
                        v-if="form"
                        :title="$t('job.change_status')"
                        :class="{disabled: $wait.is('submitting-status')}"
                    >
                        <form
                            data-vv-scope="status"
                            @submit.prevent="submit(saveChangeStatus, 'status')"
                        >
                            <y-form
                                v-model="model"
                                :params="form"
                            />

                            <div class="ta-l">
                                <y-button
                                    color="blue"
                                    loading-on="submitting-status"
                                >
                                    {{ $t('button.submit') }}
                                </y-button>
                            </div>
                        </form>
                    </y-panel>

                    <y-panel
                        v-if="form"
                        :title="$t('job.user_history')"
                        :class="{disabled: $wait.is('submitting-note')}"
                    >
                        <form
                            data-vv-scope="note"
                            @submit.prevent="submit(saveStickyNote, 'note')"
                        >
                            <y-form-editor
                                v-model="note"
                                name="note"
                                validation="required"
                                scope="note"
                                toolbar="comment"
                            />

                            <div class="ta-l">
                                <y-button
                                    color="blue"
                                    loading-on="submitting-note"
                                >
                                    {{ $t('button.submit') }}
                                </y-button>
                            </div>
                        </form>
                    </y-panel>
                </div>
            </div>
        </main>
    </y-loading>
</template>

<script>

    // Mixins
    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';

    import { digits } from '@nodes/helpers/number';
    import { date } from '@nodes/helpers/date';

    import { YForm, YFormEditor } from '@deps';
    import YFormEmailLabel from '@deps/form/elements/EmailLabel';
    import YTimeline from '@deps/Timeline';

    export default {

        name: 'JobSeekerSingle',

        components: {
            YForm,
            YFormEditor,
            YFormEmailLabel,
            YTimeline,
        },

        mixins: [PageMixin, FormMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('breadcrumbs.persons.users.edit'),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                app: null,

                form : null,
                model: {},

                note: null,
            };
        },

        computed: {

            /**
             * Page title
             */
            title() {
                if (!this.app) {
                    return '';
                }

                return this.app.ad.title;
            },

            /**
             * Page subtitle
             */
            subtitle() {
                if (!this.app) {
                    return '';
                }

                return `${this.$t('job.applied_at')} ${digits(date(this.app.applied_at), this.$i18n.locale)}`;
            },

        },

        methods: {

            /**
             * Fetch currencies
             *
             * @returns {*}
             */
            fetch() {
                const params = { id: this.$route.params.id };
                return this.$services.Job.seekerTimeline(params).then((response) => {
                    this.app = response.data.results;

                    this.form = response.data.metadata.action_form;
                    this.model = {
                        id    : this.$route.params.id,
                        status: response.data.results.status,
                    };

                    this.note = this.app.applicant.note;
                }).catch(((error) => {
                    this.handleError(error);
                }));
            },

            /**
             * Save change status and note
             */
            saveChangeStatus() {
                return this.$services.Job.seekerAction(this.model).then(() => {
                    this.$toast.success(this.$t('messages.save.success', { type: this.$t('job.item') }));
                    this.reFetch();
                }).catch(((error) => {
                    this.handleError(error);
                }));
            },

            /**
             * Save change status and note
             */
            saveStickyNote() {
                const params = {
                    note: this.note,
                    id  : this.app.applicant.id,
                };
                return this.$services.Job.seekerStickyNoteSave(params).then(() => {
                    this.$toast.success(this.$t('messages.save.success', { type: this.$t('job.note') }));
                }).catch(((error) => {
                    this.handleError(error);
                }));
            },

        },

    };
</script>
